import { Box, Button, Grid2 as Grid, IconButton, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import GreenDots from "../assets/homepage/Green Dots.png";
import DarkGreenCircle from "../assets/homepage/Dark Green Circle.png";
import LightGreenCircle from "../assets/homepage/Light Green Circle.png";
import OrangePickle from "../assets/homepage/Orange Pickle.png";

import FortuneTeller from "../assets/homepage/Fortune Teller.png";
import AllSpendingsEye from "../assets/homepage/All Spendings Eye.png";

import FirstDottedLine from "../assets/homepage/First Dotted Line.png";

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import PortfolioGraph from "../assets/homepage/carousel/Portfolio Graph.png";
import CategoryBreakdown from "../assets/homepage/carousel/Category Breakdown.png";

import SecondDottedLine from "../assets/homepage/Second Dotted Line.png";

import RandomTriangles from "../assets/homepage/Random Triangles.png";
import SubscriptionPlans from "../components/SubscriptionPlans";



const HeroSection = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 70px)',
            maxHeight: '850px',
            overflow: 'hidden',
        }}>
			{/* Background Images */}
			<Box sx={{
                position: 'absolute',
                top: '0',
                bottom: '0',
                width: '100%',
                zIndex: '-1',
                display: 'flex',
                justifyContent: 'space-between',
                pointerEvents: 'none',
            }}>
                {/* Top-Left Green Dots */}
                <Box sx={{
                    position: 'absolute',
                    left: '80px',
                    top: '50px',
                    width: '60px',
                    height: 'auto',

                    '@media (max-width: 900px)': {
                        left: '60px',
                        width: '50px',
                    },
                    '@media (max-width: 720px)': {
                        left: '40px',
                        width: '30px',
                    },
                }}><img src={GreenDots} alt="" width="100%" height="auto" /></Box>

                {/* Bottom-Left Dark Green Circle */}
                <Box sx={{
                    position: 'absolute',
                    left: '0',
                    bottom: '30px',
                    width: '200px',
                    height: 'auto',

                    '@media (max-width: 900px)': {
                        width: '160px',
                    },
                    '@media (max-width: 720px)': {
                        width: '130px',
                    },
                }}><img src={DarkGreenCircle} alt="" width="100%" height="auto" /></Box>

                {/* Top-Right Light Green Circle */}
                <Box sx={{
                    position: 'absolute',
                    right: '0',
                    top: '0px',
                    width: '320px',
                    height: 'auto',

                    '@media (max-width: 900px)': {
                        width: '240px',
                    },
                    '@media (max-width: 720px)': {
                        width: '180px',
                    },
                }}>
                    <img src={LightGreenCircle} alt="" width="100%" height="auto" />
                </Box>

                {/* Bottom-Right Orange Pickle */}
                <Box sx={{
                    position: 'absolute',
                    right: '0',
                    bottom: '0px',
                    width: '300px',
                    height: 'auto',

                    '@media (max-width: 900px)': {
                        width: '280px',
                    },
                    '@media (max-width: 720px)': {
                        width: '220px',
                    },
                }}>
                    <img src={OrangePickle} alt="" width="100%" height="auto" />
                </Box>
			</Box>

            {/* Main Content */}
            <Box textAlign="center" sx={{
                marginLeft: '20px',
                marginRight: '20px',
            }}>
                {/* Spend Wisely Live Freely */}
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ 
                        fontFamily: 'Outfit', 
                        textTransform: 'none', 
                        fontWeight: '600', 
                        fontSize: '64px', 
                        lineHeight: '80%',
                        color: '#151313',

                        '@media (max-width: 600px)': {
                            fontSize: '48px',
                        },
                        '@media (max-width: 400px)': {
                            fontSize: '36px',
                        }
                    }}>
                        Spend Wisely
                    </Typography>
                    <Typography sx={{ 
                        fontFamily: 'Outfit', 
                        textTransform: 'none', 
                        fontWeight: '600', 
                        fontSize: '64px', 
                        color: '#151313',

                        '@media (max-width: 600px)': {
                            fontSize: '48px',
                        },
                        '@media (max-width: 400px)': {
                            fontSize: '36px',
                        } 
                    }}>
                        Live Freely
                    </Typography>
                </Box>

                {/* Use Fisca Subheading */}
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ 
                        fontFamily: 'Outfit', 
                        textTransform: 'none', 
                        fontSize: '20px', 
                        color: '#151313',

                        maxWidth: '580px',

                        marginTop: '30px',

                        '@media (max-width: 600px)': {
                            fontSize: '16px',
                            marginTop: '20px',
                        },
                    }}>
                        Use Fisca to keep track of your spending - that's anytime, anywhere. 
                    </Typography>
                </Box>

                {/* Get Started Button */}
                <Button variant="contained" sx={{
                    backgroundColor: '#C4DA50',
                    color: '#000',
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontSize: '16px',
                    border: '1px solid #0C6539',
                    borderRadius: '20px',
                    padding: '5px 20px',
                    boxShadow: '4px 4px 0px #151313 !important',
                    marginTop: '45px',

                    '@media (max-width: 600px)': {
                        marginTop: '30px',
                    }
                }} onClick={ () => navigate('/pricing') }>Get Started</Button>
            </Box>
        </Box>
    );
};

const ThreeKeyFeaturesBlock = () => (
    <Grid container rowGap='20px' columnSpacing='10px' sx={{ alignItems: "stretch" }}>
        {/* Fortune Teller */}
        <Grid size={12}>
            <Box sx={{
                borderRadius: '20px',
                backgroundColor: '#009B8D',
                marginTop: '75px',
            }}>
                <Grid container>
                    <Grid size={{ xs: 12, md: 6 }} display="flex" justifyContent="center">
                        <Box sx={{
                            marginTop: '-75px',
                            width: '100%',
                            maxWidth: '415px',
                            pointerEvents: 'none',
                        }}>
                            <img src={FortuneTeller} alt="" width="100%" height="auto" />
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }} padding='40px' paddingTop={{
                        xs: 0,
                        md: '40px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                            <Typography sx={{
                                fontFamily: 'Outfit',
                                textTransform: 'none',
                                fontWeight: '500',
                                fontSize: '48px',
                                lineHeight: '120%',
                                color: '#FFFFFF',

                                '@media (max-width: 450px)': {
                                    fontSize: '36px',
                                },
                            }}>Forecast Your Expenses</Typography>
                            <Typography sx={{
                                fontFamily: 'Outfit',
                                textTransform: 'none',
                                fontWeight: '400',
                                fontSize: '24px',
                                color: '#FFFFFF',
                                marginTop: '12px',
                                
                                '@media (max-width: 450px)': {
                                    fontSize: '16px',
                                },
                            }}>
                                With real-time breakdowns of spending across multiple categories, we've got you covered!
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>

        {/* Save Money */}
        <Grid size={{ xs: 12, md: 6 }}>
            <Box sx={{
                borderRadius: '20px',
                backgroundColor: '#006564',
                height: '100%',
            }}>
                <Box sx={{                    
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: '40px',
                }}>
                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '500',
                        fontSize: '48px',
                        lineHeight: '120%',
                        color: '#FFFFFF',

                        '@media (max-width: 450px)': {
                            fontSize: '36px',
                        },
                    }}>We'll Help You Save Money</Typography>

                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '24px',
                        color: '#FFFFFF',
                        marginTop: '12px',
                        
                        '@media (max-width: 450px)': {
                            fontSize: '16px',
                        },
                    }}>
                        Using our flexible budgeting tools, getting control of your spending and saving has never been easier!
                    </Typography>
                </Box>
            </Box>
        </Grid>

        {/* All Spending In One App */}
        <Grid size={{ xs: 12, md: 6 }}>
            <Box sx={{
                borderRadius: '20px',
                backgroundColor: '#C4DA50',
                height: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: '40px',
                    paddingTop: 0,
                }}>
                    <Box sx={{
                        width: '100%',
                        maxWidth: '210px',
                        marginBottom: '20px',
                        marginLeft: 'auto',

                        '@media (max-width: 900px)': {
                            maxWidth: '160px',
                            marginRight: 'auto',
                        },
                        pointerEvents: 'none',
                    }}>
                        <img src={AllSpendingsEye} alt="" width="100%" height="auto" />
                    </Box>
                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '500',
                        fontSize: '48px',
                        lineHeight: '120%',
                        color: '#FFFFFF',

                        '@media (max-width: 450px)': {
                            fontSize: '36px',
                        },
                    }}>All Spendings In One App</Typography>

                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '24px',
                        color: '#FFFFFF',
                        marginTop: '12px',
                        
                        '@media (max-width: 450px)': {
                            fontSize: '16px',
                        },
                    }}>
                        Find out where all your hard earned money is going with our reports. 
                    </Typography>
                </Box>
            </Box>
        </Grid>
    </Grid>
);

const FirstDottedLineSection = () => (
    <Box sx={{
        textAlign: 'center',
        width: '100%',
        height: '150px',
        marginTop: '51px',
        marginBottom: '51px',
        pointerEvents: 'none',

        '@media (max-width: 900px)': {
            marginTop: '30px',
            marginBottom: '30px',
        },
    }}>
        <img src={ FirstDottedLine } alt="" width="auto" height="100%" />
    </Box>
);

const NotJustABudgetingToolCarousel = () => {

    const carouselItems = [
        {
            image: PortfolioGraph,
            title: 'Visualize Your Portfolio',
            description: "With real-time breakdowns of spending across multiple categories, we've got you covered!",
        },
        {
            image: CategoryBreakdown,
            title: 'Track Your Spending',
            description: "With real-time breakdowns of spending across multiple categories, we've got you covered!",
        },
    ];
    const [ currentItemIndex, setCurrentItemIndex ] = useState(0);
    const [ currentAnimationDirection, setCurrentAnimationDirection ] = useState<'left' | 'right'>('left');
    const previousItem = () => {
        setCurrentAnimationDirection('right');
        setCurrentItemIndex((currentItemIndex - 1 + carouselItems.length) % carouselItems.length);
    };
    const nextItem = () => {
        setCurrentAnimationDirection('left');
        setCurrentItemIndex((currentItemIndex + 1) % carouselItems.length);
    }
    const moveToItem = (index: number) => {
        if(index < currentItemIndex) {
            setCurrentAnimationDirection('right');
        } else {
            setCurrentAnimationDirection('left');
        }
        setCurrentItemIndex(index);
    };

    return (
        <Box>
            {/* Text */}
            <Typography sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '96px',
                lineHeight: '110%',
                color: '#151313',
                textAlign: 'center',
                marginBottom: '72px',

                '@media (max-width: 800px)': {
                    fontSize: '72px',
                },
                '@media (max-width: 600px)': {
                    fontSize: '54px',
                    marginBottom: '32px',
                },
            }}>It's Not <span style={{
                fontFamily: 'Arimo Hebrew Subset Italic',
                color: '#35BFC9',
                fontStyle: 'italic',
            }}>Just</span> A Budgeting Tool</Typography>

            {/* Image Carousel */}
            <Box sx={{
                width: '100%',
                backgroundColor: '#009B8D',
            }}>
                <Grid container>
                    {/* Image Preview */}
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',

                            backgroundColor: '#faf0e8',
                        }}>
                            <Box sx={{
                                width: '100%',
                                maxWidth: '400px',
                                pointerEvents: 'none',
                                overflow : 'hidden',
                                maxHeight: '580px',
                            }}>
                                {
                                    carouselItems.map((item, index) => (
                                        <Slide key={index} direction={currentAnimationDirection} in={index === currentItemIndex} timeout={500} style={{
                                            display: index === currentItemIndex ? 'block' : 'none',
                                        }}>
                                            <img src={ item.image } alt="" width="100%" height="auto" />
                                        </Slide>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Grid>

                    {/* Description */}
                    <Grid size={{ xs: 12, md: 6 }} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        padding: '40px',
                        minHeight: '300px',
                    }}>
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            textTransform: 'none',
                            fontWeight: '500',
                            lineHeight: '110%',
                            fontSize: '48px',
                            color: '#FFFFFF',
                            textAlign: 'left',

                            '@media (max-width: 500px)': {
                                fontSize: '36px',
                            },
                        }}>{ carouselItems[currentItemIndex].title }</Typography>
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            textTransform: 'none',
                            fontWeight: '400',
                            fontSize: '24px',
                            color: '#FFFFFF',
                            marginTop: '12px',
                            textAlign: 'left',
                            
                            '@media (max-width: 500px)': {
                                fontSize: '16px',
                            },
                        }}>
                            { carouselItems[currentItemIndex].description }
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',

                            width: '100%',
                            marginTop: '20px',
                        }}>
                            <IconButton>
                                <ArrowLeftIcon onClick={ previousItem } sx={{
                                    fontSize: '48px',
                                    color: '#FFFFFF',
                                    width: '48px',
                                    height: '48px',
                                }} />
                            </IconButton>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}>
                                { carouselItems.map((item, index) => (
                                    <Box key={index} sx={{
                                        width: '16px',
                                        height: '16px',
                                        borderRadius: '8px',
                                        backgroundColor: index === currentItemIndex ? '#FFFFFF' : '#FFFFFF55',
                                        marginLeft: '9px',
                                        marginRight: '9px',
                                        transition: 'background-color 0.3s',
                                        cursor: index === currentItemIndex ? 'default' : 'pointer',
                                    }} onClick={ () => {
                                        if(index !== currentItemIndex) moveToItem(index);
                                    }} />
                                )) }
                            </Box>
                            
                            <IconButton>
                                <ArrowRightIcon onClick={ nextItem } sx={{
                                    fontSize: '48px',
                                    color: '#FFFFFF',
                                    width: '48px',
                                    height: '48px',
                                }} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const SecondDottedLineSection = () => (
    <Box sx={{
        textAlign: 'center',
        width: '100%',
        height: '300px',
        marginTop: '51px',
        marginBottom: '51px',
        pointerEvents: 'none',

        '@media (max-width: 900px)': {
            marginTop: '30px',
            marginBottom: '30px',
        },
    }}>
        <img src={ SecondDottedLine } alt="" width="auto" height="100%" />
    </Box>
);

const NoAdsAndSecurityBlocks = () => (
    <Grid container rowGap='20px' columnSpacing='10px' sx={{ alignItems: "stretch" }}>
        {/* No Ads */}
        <Grid size={{ xs: 12, md: 6 }}>
            <Box sx={{
                borderRadius: '20px',
                backgroundColor: '#C4DA50',
                height: '100%',
            }}>
                <Box sx={{                    
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: '40px',
                }}>
                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '500',
                        fontSize: '48px',
                        lineHeight: '120%',
                        color: '#FFFFFF',

                        '@media (max-width: 450px)': {
                            fontSize: '36px',
                        },
                    }}>No Ads, Privacy First</Typography>

                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '24px',
                        color: '#FFFFFF',
                        marginTop: '12px',
                        
                        '@media (max-width: 450px)': {
                            fontSize: '16px',
                        },
                    }}>
                        Our goal is to help you manage your money better, so we don't use ads to support our service and we don't sell your data. 
                        Your data is yours and yours alone.
                    </Typography>
                </Box>
            </Box>
        </Grid>

        {/* Security First */}
        <Grid size={{ xs: 12, md: 6 }}>
            <Box sx={{
                borderRadius: '20px',
                backgroundColor: '#006564',
                height: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: '40px',
                }}>
                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '500',
                        fontSize: '48px',
                        lineHeight: '120%',
                        color: '#FFFFFF',

                        '@media (max-width: 450px)': {
                            fontSize: '36px',
                        },
                    }}>
                        Security First Approach
                    </Typography>

                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '24px',
                        color: '#FFFFFF',
                        marginTop: '12px',
                        
                        '@media (max-width: 450px)': {
                            fontSize: '16px',
                        },
                    }}>
                        We take security seriously. We use top-level security practises to keep your data safe and secure at all times. All your data is encrypted and stored securely.
                    </Typography>
                </Box>
            </Box>
        </Grid>
    </Grid>
);

const FindYourFinancialPathSection = () => (
    <Box sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginTop: '51px',
    }}>
        {/* Background Images */}
        <Box sx={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            width: '100%',
            zIndex: '-1',
            display: 'flex',
            justifyContent: 'space-between',
            pointerEvents: 'none',
        }}>
            {/* Top-Left Orange Pickle */}
            <Box sx={{
                position: 'absolute',
                left: '0px',
                top: '30px',
                width: '350px',
                height: 'auto',

                '@media (max-width: 1275px)': {
                    width: '250px',
                },
                '@media (max-width: 1150px)': {
                    top: '90px',
                    width: '170px',
                },
                '@media (max-width: 800px)': {
                    top: '100px',
                    width: '150px',
                },
                '@media (max-width: 550px)': {
                    top: '60px',
                    width: '100px',
                }
            }}><img src={OrangePickle} alt="" width="100%" height="auto" style={{
                transform: 'rotate(180deg)',
            }} /></Box>

            {/* Bottom-Left Random Triangles */}
            <Box sx={{
                position: 'absolute',
                left: '30px',
                bottom: '30px',
                width: '300px',
                height: 'auto',

                '@media (max-width: 1000px)': {
                    width: '200px',
                },
                '@media (max-width: 800px)': {
                    width: '70px',
                    left: '-10px',
                },
            }}><img src={RandomTriangles} alt="" width="100%" height="auto" /></Box>

            {/* Top-Right Dots Circle */}
            <Box sx={{
                position: 'absolute',
                right: '120px',
                top: '150px',
                width: '80px',
                height: 'auto',

                '@media (max-width: 1000px)': {
                    top: '200px',
                    right: '80px',
                    width: '60px',
                },
                '@media (max-width: 800px)': {
                    top: '220px',
                    right: '60px',
                    width: '40px',
                },
                '@media (max-width: 620px)': {
                    top: '260px',
                    right: '5px',
                    width: '30px',
                },
            }}>
                <img src={GreenDots} alt="" width="100%" height="auto" style={{ transform: 'rotate(180deg)', }} />
            </Box>

            {/* Bottom-Right Dark Green Circle */}
            <Box sx={{
                position: 'absolute',
                right: '0',
                bottom: '10px',
                width: '300px',
                height: 'auto',

                '@media (max-width: 1275px)': {
                    width: '240px',
                },
                '@media (max-width: 1000px)': {
                    width: '200px',
                },
                '@media (max-width: 800px)': {
                    width: '140px',
                },
                '@media (max-width: 650px)': {
                    bottom: '0px',
                    width: '40px',
                },
            }}>
                <img src={DarkGreenCircle} alt="" width="100%" height="auto" style={{ transform: 'rotate(180deg)' }} />
            </Box>
        </Box>

        {/* Main Content */}
        <Box textAlign="center" sx={{
            marginLeft: '20px',
            marginRight: '20px',
        }}>
            {/* Find Your Financial Path */}
            <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ 
                    fontFamily: 'Outfit', 
                    textTransform: 'none', 
                    fontWeight: '600', 
                    fontSize: '64px', 
                    lineHeight: '80%',
                    color: '#151313',

                    '@media (max-width: 820px)': {
                        fontSize: '48px',
                    },
                    '@media (max-width: 600px)': {
                        fontSize: '36px',
                    },
                    '@media (max-width: 400px)': {
                        fontSize: '28px',
                    }
                }}>
                    Everyone Finds Their
                </Typography>
                <Typography sx={{ 
                    fontFamily: 'Outfit', 
                    textTransform: 'none', 
                    fontWeight: '600', 
                    fontSize: '64px', 
                    color: '#151313',

                    '@media (max-width: 820px)': {
                        fontSize: '48px',
                    },
                    '@media (max-width: 600px)': {
                        fontSize: '36px',
                    },
                    '@media (max-width: 400px)': {
                        fontSize: '28px',
                    }
                }}>
                    Financial Path
                </Typography>
            </Box>

            {/* Use Fisca Subheading */}
            <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ 
                    fontFamily: 'Outfit', 
                    textTransform: 'none', 
                    fontSize: '20px', 
                    color: '#151313',

                    '@media (max-width: 820px)': {
                        fontSize: '18px',
                    },
                    '@media (max-width: 600px)': {
                        fontSize: '16px',
                    },
                }}>
                    Where Will Yours Start?
                </Typography>
            </Box>

            {/* Plan Selector */}
            <SubscriptionPlans />
        </Box>
    </Box>
);



export default () => (
	<>
        <HeroSection />

        {/* Main Content */}
        <Box sx={{
            width: '100%',
            maxWidth: '900px',
            marginLeft: 'auto',
            marginRight: 'auto',
        }}>
            <Box sx={{
                paddingLeft: '35px',
                paddingRight: '35px',
            }}>
                <ThreeKeyFeaturesBlock />
                <FirstDottedLineSection />
                <NotJustABudgetingToolCarousel />
                <SecondDottedLineSection />
                <NoAdsAndSecurityBlocks />
            </Box>
        </Box>
        
        <FindYourFinancialPathSection />
	</>
);