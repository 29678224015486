import { Box, Typography } from "@mui/material";



const ContactBox = () => (
    <Box sx={{ borderRadius: '20px', backgroundColor: '#009B8D', padding: '40px' }}>
        <Typography sx={{
            fontFamily: 'Outfit',
            textTransform: 'none',
            fontWeight: '500',
            fontSize: '48px',
            lineHeight: '120%',
            color: '#FFFFFF',

            '@media (max-width: 450px)': {
                fontSize: '36px',
            },
        }}>Get In Contact</Typography>
        <Typography sx={{
            fontFamily: 'Outfit',
            textTransform: 'none',
            fontWeight: '400',
            fontSize: '24px',
            color: '#FFFFFF',
            marginTop: '12px',
            
            '@media (max-width: 450px)': {
                fontSize: '16px',
            },
        }}>
            We're here to help you with any questions you might have. Get in touch with us today.
            <br /><br />
            Email: <a href="mailto:support@fisca.uk" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>support@fisca.uk</a>
            <br /><br />
            If you have any questions about our service, please don't hesitate to get in touch.
        </Typography>
    </Box>
);



export default () => (
    <Box sx={{
        width: '100%',
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
    }}>
        <Box sx={{
            paddingLeft: '35px',
            paddingRight: '35px',
        }}>
            <ContactBox />
        </Box>
    </Box>
);