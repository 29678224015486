import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default () => (    
    <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '50px',
        paddingRight: '50px',
        minHeight: '70px',
        marginTop: '20px',
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            maxWidth: '1300px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '20px',
        }}>
            <Typography sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '12px',
                color: '#999999',
                textAlign: 'center',
                marginTop: '12px',
                width: '100%',
            }}>
                &#169; Fisca is a product of Megotrevo Ltd registered in England and Wales (Reg No 16019447).
                Megotrevo is a UK based company producing consumer software products.
            </Typography>

            <Grid container width="100%" justifyContent="space-between" alignItems="center">
                <Grid size={12} textAlign={{ xs: "center", sm: "left" }}>
                    <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '600', fontSize: '13px', color: '#999999', marginTop: '12px' }}>Site Map</Typography>
                </Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "left" }}>
                    <a href="/" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>Home</Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "center" }}>
                    <a href="/about" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>About</Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "center" }}>
                    <a href="/pricing" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>Pricing</Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "right" }}>
                    <a href="/contact-us" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>Contact Us</Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "left" }} marginTop={{ xs: 0, sm: '3px' }}>
                    <a href={ process.env.REACT_APP_APPLICATION_SITE_URL + "/auth/login" } style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>Login</Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "center" }} marginTop={{ xs: 0, sm: '3px' }}>
                    <a href={ process.env.REACT_APP_APPLICATION_SITE_URL + "/auth/signup" } style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>Sign Up</Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "center" }}></Grid>
                <Grid size={{ xs: 6, sm: 3 }} textAlign={{ xs: "center", sm: "right" }}></Grid>
            </Grid>

            <Grid container width="100%" justifyContent="space-between" alignItems="center">
                <Grid size={12} textAlign={{ xs: "center", sm: "left" }}>
                    <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '600', fontSize: '13px', color: '#999999', marginTop: '12px' }}>Additional Documents</Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }} textAlign={{ xs: "center", sm: "left" }}>
                    <a href={ process.env.REACT_APP_TERMS_AND_CONDITIONS_URL } target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>
                            Terms and Conditions
                        </Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }} textAlign="center">
                    <a href={ process.env.REACT_APP_PRIVACY_POLICY_URL } target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>
                            Privacy Policy
                        </Typography>
                    </a>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }} textAlign={{ xs: "center", sm: "right" }}>
                    <a href={ process.env.REACT_APP_LEGAL_NOTICE_AND_DISCLAIMER_URL } target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontFamily: 'Outfit', textTransform: 'none', fontWeight: '500', fontSize: '12px', color: '#999999' }}>
                            Legal Notice
                        </Typography>
                    </a>
                </Grid>
            </Grid>
        </Box>
    </Box>
);