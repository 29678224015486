import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";



export default () => {
    const navigate = useNavigate();

    return (
        <Box sx={{
            width: '100%',
            maxWidth: '900px',
            marginLeft: 'auto',
            marginRight: 'auto',
        }}>
            <Box sx={{
                paddingLeft: '35px',
                paddingRight: '35px',
                textAlign: 'center',
            }}>
                <Typography sx={{ 
                    fontFamily: 'Outfit', 
                    textTransform: 'none', 
                    fontWeight: '600', 
                    fontSize: '64px', 
                    lineHeight: '80%',
                    color: '#151313',

                    '@media (max-width: 600px)': {
                        fontSize: '48px',
                    },
                    '@media (max-width: 400px)': {
                        fontSize: '36px',
                    }
                }}>Page Not Found</Typography>
                <Typography sx={{ 
                    fontFamily: 'Outfit', 
                    textTransform: 'none', 
                    fontSize: '20px', 
                    color: '#151313',

                    marginTop: '30px',

                    '@media (max-width: 600px)': {
                        fontSize: '16px',
                        marginTop: '20px',
                    },
                }}>Woah! How'd you get here?</Typography>
                <Typography sx={{ 
                    fontFamily: 'Outfit', 
                    textTransform: 'none', 
                    fontSize: '20px', 
                    color: '#151313',

                    marginTop: '30px',

                    '@media (max-width: 600px)': {
                        fontSize: '16px',
                        marginTop: '20px',
                    },
                }}>Let's get you back to the right place.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '60px' }}>
                    {/* Get Started Button */}
                    <Button variant="contained" sx={{
                        backgroundColor: '#C4DA50',
                        color: '#000',
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '600',
                        fontSize: '16px',
                        border: '1px solid #0C6539',
                        borderRadius: '20px',
                        padding: '5px 20px',
                        boxShadow: '4px 4px 0px #151313 !important',
                        marginTop: '45px',

                        '@media (max-width: 600px)': {
                            marginTop: '30px',
                        }
                    }} onClick={ () => navigate('/') }>Go Home</Button>
                </Box>
            </Box>
        </Box>
    );
};