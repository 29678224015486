import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./Homepage";
import { Box } from "@mui/material";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import AboutUs from "./AboutUs";
import { useEffect } from "react";
import ContactUs from "./ContactUs";
import Pricing from "./Pricing";
import PageNotFound from "./PageNotFound";



const ScrollToTop = (props: any) => {
    const location = useLocation();
    useEffect(() => window.scrollTo(0, 0), [ location ]);
  
    return (
        <>
            {props.children}
        </>
    );
};
  

const PageContents = () => (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '20px',
        minHeight: '500px',
    }}>
        <ScrollToTop>
            <Routes>
                <Route path="/" element={ <Homepage /> } />
                <Route path="/about" element={ <AboutUs /> } />
                <Route path="/pricing" element={ <Pricing /> } />
                <Route path="/contact-us" element={ <ContactUs /> } />
                <Route path="*" element={ <PageNotFound /> } /> 
            </Routes>
        </ScrollToTop>
    </Box>
);


export default () => (
    <Router>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }}>
            <Navigation />
            <PageContents />
            <Footer />
        </Box>
    </Router>
);