import { Box, Button, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { Linking } from "react-native";



export default () => (
    <Box sx={{
        marginTop: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: '448px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        borderRadius: '48px',
        backgroundColor: '#fff',
                    
        '@media (max-width: 820px)': {
            maxWidth: '375px',
        },
        '@media (max-width: 520px)': {
            maxWidth: '300px',
        },
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            borderTopLeftRadius: '48px',
            borderTopRightRadius: '48px',
            backgroundColor: '#3FBDBA',
        }}>
            <Typography sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '700',
                fontSize: '32px',
                color: '#FFFFFF',
                marginTop: '20px',
                
                '@media (max-width: 820px)': {
                    fontSize: '28px',
                },
                '@media (max-width: 520px)': {
                    fontSize: '24px',
                },
                '@media (max-width: 450px)': {
                    fontSize: '20px',
                },
            }}>
                Starter
            </Typography>
            <Typography sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '500',
                fontSize: '24px',
                color: '#FFFFFF',
                marginBottom: '20px',
                
                '@media (max-width: 820px)': {
                    fontSize: '20px',
                },
                '@media (max-width: 520px)': {
                    fontSize: '18px',
                },
                '@media (max-width: 450px)': {
                    fontSize: '16px',
                },
            }}>
                Get control of your spending
            </Typography>
        </Box>

        <Typography sx={{
            fontFamily: 'Outfit',
            textTransform: 'none',
            fontWeight: '600',
            fontSize: '32px',
            color: '#151313',
            marginTop: '20px',
            textAlign: 'center',
            lineHeight: '110%',
            
            '@media (max-width: 820px)': {
                fontSize: '28px',
            },
            '@media (max-width: 520px)': {
                fontSize: '24px',
            },
            '@media (max-width: 450px)': {
                fontSize: '20px',
            },
        }}>
            £7.50
        </Typography>
        <Typography sx={{
            fontFamily: 'Outfit',
            textTransform: 'none',
            fontWeight: '400',
            fontSize: '24px',
            color: '#414142',
            marginTop: '0px',
            textAlign: 'center',
            
            '@media (max-width: 820px)': {
                fontSize: '20px',
            },
            '@media (max-width: 520px)': {
                fontSize: '18px',
            },
            '@media (max-width: 450px)': {
                fontSize: '16px',
            },
        }}>
            per month, billed monthly
        </Typography>

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '30px',

            marginLeft: 'auto',
            marginRight: 'auto',
            
            '@media (max-width: 520px)': {
                marginTop: '20px',
            },
        }}>
            {
                [
                    'Manual transaction imports',
                    'Customisable budgets',
                    'Smart goal management',
                    'Income and expense graphs',
                    'Portfolio value over time',
                ].map((feature) => (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', columnGap: '12px' }}>
                        <CheckIcon sx={{ 
                            color: '#3FBDBA', 
                            fontSize: '32px',

                            '@media (max-width: 520px)': {
                                fontSize: '24px',
                            },
                        }} />
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            textTransform: 'none',
                            fontWeight: '400',
                            fontSize: '24px',
                            color: '#414142',
                            textAlign: 'left',

                            '@media (max-width: 820px)': {
                                fontSize: '20px',
                            },
                            '@media (max-width: 520px)': {
                                fontSize: '16px',
                            },
                            '@media (max-width: 450px)': {
                                fontSize: '14px',
                            },
                        }}>{ feature }</Typography>
                    </Box>
                ))
            }
        </Box>
        
        <Button variant="contained" sx={{
            backgroundColor: '#3FBDBA',
            color: '#fff',
            fontFamily: 'Outfit',
            textTransform: 'none',
            fontWeight: '600',
            fontSize: '24px',
            borderRadius: '40px',
            boxShadow: 'none !important',
            marginTop: '32px',
            marginBottom: '32px',
            width: '100%',
            maxWidth: '250px',
            marginLeft: 'auto',
            marginRight: 'auto',
            
            '@media (max-width: 520px)': {
                marginTop: '20px',
            },
            '@media (max-width: 450px)': {
                maxWidth: '200px',
                fontSize: '20px',
            },
        }} onClick={ () => Linking.openURL(process.env.REACT_APP_APPLICATION_SITE_URL + '/auth/signup?plan_name=Starter') }>Get Started</Button>
    </Box>
);