import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";



const AboutUsBoxes = () => (
    <Grid container rowGap='20px' columnSpacing='10px' sx={{ alignItems: "stretch", marginTop: '20px' }}>
        {/* We Are Megotrevo */}
        <Grid size={12}>
            <Box sx={{ borderRadius: '20px', backgroundColor: '#009B8D', padding: '40px' }}>
                <Typography sx={{
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                    fontWeight: '500',
                    fontSize: '48px',
                    lineHeight: '120%',
                    color: '#FFFFFF',

                    '@media (max-width: 450px)': {
                        fontSize: '36px',
                    },
                }}>We Are Megotrevo</Typography>
                <Typography sx={{
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '24px',
                    color: '#FFFFFF',
                    marginTop: '12px',
                    
                    '@media (max-width: 450px)': {
                        fontSize: '16px',
                    },
                }}>
                    A UK based company dedicated to helping you save money and take control of your finances. We're developing software to help you manage your money better.
                </Typography>
            </Box>
        </Grid>

        {/* We Value Customer Experience */}
        <Grid size={{ xs: 12, md: 6 }}>
            <Box sx={{ borderRadius: '20px', backgroundColor: '#006564', height: '100%', }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '40px', }}>
                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '500',
                        fontSize: '48px',
                        lineHeight: '120%',
                        color: '#FFFFFF',

                        '@media (max-width: 450px)': {
                            fontSize: '36px',
                        },
                    }}>We Value Your Experience</Typography>

                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '24px',
                        color: '#FFFFFF',
                        marginTop: '12px',
                        
                        '@media (max-width: 450px)': {
                            fontSize: '16px',
                        },
                    }}>
                        We are constantly improving our software to make sure you have the best experience possible. Every feature we add, each bug we fix, is to make your life easier.
                    </Typography>
                </Box>
            </Box>
        </Grid>

        {/* Dedicated to Helping You */}
        <Grid size={{ xs: 12, md: 6 }}>
            <Box sx={{ borderRadius: '20px', backgroundColor: '#C4DA50', height: '100%', }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '40px', }}>
                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '500',
                        fontSize: '48px',
                        lineHeight: '120%',
                        color: '#FFFFFF',

                        '@media (max-width: 450px)': {
                            fontSize: '36px',
                        },
                    }}>
                        Dedicated to Helping You
                    </Typography>

                    <Typography sx={{
                        fontFamily: 'Outfit',
                        textTransform: 'none',
                        fontWeight: '400',
                        fontSize: '24px',
                        color: '#FFFFFF',
                        marginTop: '12px',
                        
                        '@media (max-width: 450px)': {
                            fontSize: '16px',
                        },
                    }}>
                        We're here to help you every step of the way. Fisca is a tool to help you manage your money, and we're here to help you use it to its full potential.
                    </Typography>
                </Box>
            </Box>
        </Grid>
    </Grid>
);

const FindOutMore = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '60px' }}>
            {/* Get Started Button */}
            <Button variant="contained" sx={{
                backgroundColor: '#C4DA50',
                color: '#000',
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '16px',
                border: '1px solid #0C6539',
                borderRadius: '20px',
                padding: '5px 20px',
                boxShadow: '4px 4px 0px #151313 !important',
                marginTop: '45px',

                '@media (max-width: 600px)': {
                    marginTop: '30px',
                }
            }} onClick={ () => navigate('/') }>Find Out More</Button>
        </Box>
    );
};



export default () => (
    <Box sx={{
        width: '100%',
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
    }}>
        <Box sx={{
            paddingLeft: '35px',
            paddingRight: '35px',
        }}>
            <AboutUsBoxes />
            <FindOutMore />
        </Box>
    </Box>
);