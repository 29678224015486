import { Box, Button, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Logo from "../assets/Logo - Light Transparent.png";
import { useState } from "react";
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useLocation, useNavigate } from "react-router-dom";
import { Linking } from "react-native";



const LogoHomeButton = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '230px',

            cursor: 'pointer',

            '@media (max-width: 1000px)': {
                width: '100px',
            },
        }} onClick={ () => navigate('/') }>
            <img src={Logo} width={28} height={38} />

            <Typography sx={{
                fontFamily: 'Outfit',
                fontWeight: 'bold',
                fontSize: '24px',
                paddingLeft: '9px',
                color: '#414142'
            }}>Fisca</Typography>
        </Box>
    );
};

const ExpandedNavigationItems = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '32px',
            justifyContent: 'space-between',
            alignItems: 'center',

            '@media (max-width: 1000px)': {
                display: 'none'
            },
        }}>
            <Button variant="text" sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '16px',
                color: '#000'
            }} onClick={ () => navigate('/') }>Home</Button>
            <Button variant="text" sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '16px',
                color: '#000'
            }} onClick={ () => navigate('/about') }>About</Button>
            <Button variant="text" sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '16px',
                color: '#000'
            }} onClick={ () => navigate('/pricing') }>Pricing</Button>
            <Button variant="text" sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '16px',
                color: '#000'
            }} onClick={ () => navigate('/contact-us') }>Contact Us</Button>
        </Box>
    );
};

const CollapsedNavigationItems = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100px',

            '@media (min-width: 1001px)': {
                display: 'none'
            },
        }}>
            <Button variant="contained" sx={{
                backgroundColor: '#006564',
                color: '#fff',
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '16px',
                border: '1px solid #000000',
                borderRadius: '20px',
                padding: '5px 20px',
                boxShadow: '4px 4px 0px #151313 !important',
            }} onClick={ () => setIsOpen(!isOpen) }>Menu</Button>

            <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)} onClick={() => setIsOpen(false)}>
                <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: '16px' }}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={ () => navigate('/') }>
                                <ListItemText primaryTypographyProps={{ sx: {
                                    fontFamily: 'Outfit',
                                    fontWeight: (!location.pathname || location.pathname === "/") ? '600' : '400',
                                    fontSize: '16px',
                                    color: '#000'
                                }}} primary="Home" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={ () => navigate('/about') }>
                                <ListItemText primaryTypographyProps={{ sx: {
                                    fontFamily: 'Outfit',
                                    fontWeight: location.pathname === "/about" ? '600' : '400',
                                    fontSize: '16px',
                                    color: '#000'
                                }}} primary="About" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={ () => navigate('/pricing') }>
                                <ListItemText primaryTypographyProps={{ sx: {
                                    fontFamily: 'Outfit',
                                    fontWeight: location.pathname === "/pricing" ? '600' : '400',
                                    fontSize: '16px',
                                    color: '#000'
                                }}} primary="Pricing" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={ () => navigate('/contact-us') }>
                                <ListItemText primaryTypographyProps={{ sx: {
                                    fontFamily: 'Outfit',
                                    fontWeight: location.pathname === "/contact-us" ? '600' : '400',
                                    fontSize: '16px',
                                    color: '#000'
                                }}} primary="Contact Us" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={ () => Linking.openURL(process.env.REACT_APP_APPLICATION_SITE_URL + '/auth/login') }>
                                <ListItemIcon><LoginIcon /></ListItemIcon>
                                <ListItemText primaryTypographyProps={{ sx: {
                                    fontFamily: 'Outfit',
                                    fontWeight: '400',
                                    fontSize: '16px',
                                    color: '#000'
                                }}} primary="Login" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={ () => Linking.openURL(process.env.REACT_APP_APPLICATION_SITE_URL + '/auth/signup') }>
                                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                                <ListItemText primaryTypographyProps={{ sx: {
                                    fontFamily: 'Outfit',
                                    fontWeight: '400',
                                    fontSize: '16px',
                                    color: '#000'
                                }}} primary="Signup" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
};

const LoginSignupButton = () => (
    <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '20px',
        width: '230px',
        paddingRight: '4px',

        '@media (max-width: 1000px)': {
            display: 'none'
        },
    }}>
        <Button variant="text" sx={{
            fontFamily: 'Outfit',
            textTransform: 'none',
            fontWeight: '600',
            fontSize: '16px',
            color: '#000'
        }} onClick={ () => Linking.openURL(process.env.REACT_APP_APPLICATION_SITE_URL + '/auth/login') }>Login</Button>

        <Button variant="contained" sx={{
            backgroundColor: '#006564',
            color: '#fff',
            fontFamily: 'Outfit',
            textTransform: 'none',
            fontWeight: '600',
            fontSize: '16px',
            border: '1px solid #000000',
            borderRadius: '20px',
            padding: '5px 20px',
            boxShadow: '4px 4px 0px #151313 !important',
        }} onClick={ () => Linking.openURL(process.env.REACT_APP_APPLICATION_SITE_URL + '/auth/signup') }>Register Now</Button>
    </Box>
);


const WebsiteNavigation = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '1300px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
        }}>
            {/* Logo */}
            <LogoHomeButton />

            {/* Expanded Navigation Items */}
            <ExpandedNavigationItems />

            {/* Signup/Login or the Collapsed Navigation Items if the page is small */}
            <LoginSignupButton />
            <CollapsedNavigationItems />
        </Box>
    );
};


export default () => (
    <Box sx={{
        position: 'sticky',
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '35px',
        paddingRight: '35px',
        height: '70px',
        backgroundColor: '#f9f0e9',
        zIndex: 1000,
    }}>
        <WebsiteNavigation />
    </Box>
);