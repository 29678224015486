import { Box, Typography } from "@mui/material";
import SubscriptionPlans from "../components/SubscriptionPlans";



import BendyArrow from '../assets/pricing/Bendy Arrow.png';
import FirstDottedLine from '../assets/homepage/First Dotted Line.png';



const HeroSection = () => (
    <Box sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',

        height: 'calc(100vh - 70px)',
        maxHeight: '850px',

        justifyContent: 'center',
    }}>
        {/* Spend Wisely Live Freely */}
        <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ 
                fontFamily: 'Outfit', 
                textTransform: 'none', 
                fontWeight: '600', 
                fontSize: '64px', 
                lineHeight: '80%',
                color: '#151313',

                '@media (max-width: 600px)': {
                    fontSize: '48px',
                },
                '@media (max-width: 400px)': {
                    fontSize: '36px',
                }
            }}>
                It's Time To
            </Typography>
            <Typography sx={{ 
                fontFamily: 'Arimo Hebrew Subset Italic',
                color: '#35BFC9',
                fontStyle: 'italic',
                textTransform: 'none', 
                fontWeight: '600', 
                fontSize: '72px', 

                '@media (max-width: 600px)': {
                    fontSize: '64px',
                },
                '@media (max-width: 400px)': {
                    fontSize: '48px',
                } 
            }}>
                Take Control
            </Typography>
            
            <Box sx={{
                width: '168px',
                marginLeft: 'auto',
                marginRight: 'auto',

                '@media (max-width: 600px)': {
                    width: '128px',
                },
                '@media (max-width: 400px)': {
                    width: '96px',
                }
            }}>
                <img src={BendyArrow} width="100%" height="auto" alt="Bendy Arrow" />
            </Box>
        </Box>
    </Box>
);

const TermsExplaination = () => (
    <Box sx={{
        borderRadius: '20px',
        backgroundColor: '#006564',
        height: '100%',
        marginTop: '40px',
        marginBottom: '40px',
    }}>
        <Box sx={{                    
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '40px',
        }}>
            <Typography sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '500',
                fontSize: '48px',
                lineHeight: '120%',
                color: '#FFFFFF',

                '@media (max-width: 450px)': {
                    fontSize: '36px',
                },
            }}>Cancel Anytime</Typography>

            <Typography sx={{
                fontFamily: 'Outfit',
                textTransform: 'none',
                fontWeight: '400',
                fontSize: '24px',
                color: '#FFFFFF',
                marginTop: '12px',
                
                '@media (max-width: 450px)': {
                    fontSize: '16px',
                },
            }}>
                We understand that life can be unpredictable. That's why we offer a no questions asked cancellation policy. If you're not happy with our service, you can cancel at any time before your next billing cycle.
            </Typography>
        </Box>
    </Box>
);

const FirstDottedLineSection = () => (
    <Box sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',

        marginLeft: 'auto',
        marginRight: 'auto',

        marginBottom: '40px',

        width: '168px',

        '@media (max-width: 600px)': {
            width: '128px',
        },
        '@media (max-width: 400px)': {
            width: '96px',
        }
    }}>
        <img src={FirstDottedLine} width="100%" height="auto" alt="" />
    </Box>
);



export default () => (
    <Box sx={{
        width: '100%',
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
    }}>
        <Box sx={{
            paddingLeft: '35px',
            paddingRight: '35px',
        }}>
            <HeroSection />
            <TermsExplaination />
            <FirstDottedLineSection />
            <SubscriptionPlans />
        </Box>
    </Box>
);